import React from 'react';
import { navigate } from 'gatsby';

// Just a little redirect for local development and ephemeral environments on netlify
// to avoid having to look at a 404 development/netlify page.
// This shouldn't ever be hit by production, since nginx handles the 301s from the bare domain
class RedirectFromRoot extends React.Component {
  componentDidMount() {
    navigate('/en/', { replace: true });
  }

  render() {
    return null;
  }
}

export default RedirectFromRoot;
